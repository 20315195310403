import { styled } from '../../stitches.config';

interface IconProps {
    name: string
    color?: string
    rotate?: string
}

const icons = [
    {
        name: 'arrow',
        width: 12,
        height: 12,
        viewBox: '0 0 12 12',
        path: 'M6 0C6.55228 0 7 0.447715 7 1V8.58579L10.2929 5.29289C10.6834 4.90237 11.3166 4.90237 11.7071 5.29289C12.0976 5.68342 12.0976 6.31658 11.7071 6.70711L6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289C0.683417 4.90237 1.31658 4.90237 1.70711 5.29289L5 8.58579V1C5 0.447715 5.44772 0 6 0Z',
    },
    {
        name: 'chevron',
        width: 10,
        height: 6,
        viewBox: '0 0 10 6',
        path: 'M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L5 3.93934L8.46967 0.46967C8.76256 0.176777 9.23744 0.176777 9.53033 0.46967C9.82322 0.762563 9.82322 1.23744 9.53033 1.53033L5.53033 5.53033C5.23744 5.82322 4.76256 5.82322 4.46967 5.53033L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z',
    },
    {
        name: 'headphones',
        width: 15,
        height: 16,
        viewBox: '0 0 15 16',
        path: 'M7.49997 2.51773C5.29083 2.51773 3.49997 4.30859 3.49997 6.51773V8.95832C3.97327 9.16442 4.38712 9.51841 4.66505 9.9998L5.66505 11.7318C6.3554 12.9276 5.94572 14.4566 4.74998 15.1469C3.55425 15.8373 2.02527 15.4276 1.33492 14.2318L0.334927 12.4998C-0.355431 11.3041 0.0542606 9.77508 1.25 9.08473C1.33188 9.03745 1.41533 8.99533 1.49997 8.95827V6.51773C1.49997 3.20402 4.18626 0.517731 7.49997 0.517731C10.8137 0.517731 13.5 3.20402 13.5 6.51773V8.95824C13.5846 8.99531 13.6681 9.03743 13.75 9.08472C14.9457 9.77508 15.3554 11.3041 14.6651 12.4998L13.6651 14.2318C12.9747 15.4276 11.4457 15.8372 10.25 15.1469C9.05426 14.4565 8.64458 12.9275 9.33493 11.7318L10.3349 9.99978C10.6128 9.51841 11.0267 9.16443 11.5 8.95833V6.51773C11.5 4.30859 9.7091 2.51773 7.49997 2.51773ZM2.24999 10.8168C2.01084 10.9549 1.92891 11.2606 2.06698 11.4998L3.06697 13.2318C3.20504 13.471 3.51084 13.5529 3.74998 13.4149C3.98913 13.2768 4.07107 12.971 3.933 12.7318L2.933 10.9998C2.79493 10.7606 2.48914 10.6787 2.24999 10.8168ZM12.067 10.9998C12.205 10.7606 12.5108 10.6787 12.75 10.8168C12.9891 10.9548 13.0711 11.2606 12.933 11.4998L11.933 13.2318C11.7949 13.471 11.4891 13.5529 11.25 13.4148C11.0108 13.2768 10.9289 12.971 11.067 12.7318L12.067 10.9998Z',
        fillEvenOdd: true,
        clipEvenOdd: true
    },
    {
        name: 'book',
        width: 15,
        height: 14,
        viewBox: '0 0 15 14',
        path: 'M 0.91529 0.188765 C 1.17606 0.000813857 1.51128 -0.0503253 1.81623 0.051324 L 7.5 1.94591 L 13.1838 0.051324 C 13.4887 -0.0503253 13.8239 0.000813857 14.0847 0.188765 C 14.3455 0.376716 14.5 0.678564 14.5 1.00001 V 11 C 14.5 11.4304 14.2246 11.8126 13.8162 11.9487 L 7.81623 13.9487 C 7.61096 14.0171 7.38904 14.0171 7.18377 13.9487 L 1.18377 11.9487 C 0.77543 11.8126 0.5 11.4304 0.5 11 V 1.00001 C 0.5 0.678564 0.654521 0.376716 0.91529 0.188765 Z M 6.5 3.72077 L 2.5 2.38743 V 10.2792 L 6.5 11.6126 V 3.72077 Z M 8.5 11.6126 L 12.5 10.2792 V 2.38743 L 8.5 3.72077 V 11.6126 Z'
    }
]

const StyledIcon = styled('svg', {})

const Icon = ({name, color, rotate}: IconProps) => {
    const icon = icons.find(item => item.name === name && item);
    const rotation = 'rotate(' + rotate + 'deg)';
    return (
        <StyledIcon css={{transform: rotation}} fill={color ? color : 'currentColor'} width={icon?.width} height={icon?.height} viewBox={icon?.viewBox} xmlns="http://www.w3.org/2000/svg">
            <path fillRule={icon?.fillEvenOdd ? 'evenodd' : "nonzero"} clipRule={icon?.clipEvenOdd ? 'evenodd' : "nonzero"} d={icon?.path}/>
        </StyledIcon>
    )
}

export default Icon